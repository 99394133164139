<template>
  <div v-loading="isLoading" id="DepositDetail" class="h-full">
    <div class="bg-white p-4 h-full cs-container w-full mx-auto relative">
      <svg
        @click="handleBack"
        class="absolute right-4 top-5 cursor-pointer"
        width="28"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5298 24.4701C25.6035 24.5388 25.6626 24.6216 25.7036 24.7136C25.7446 24.8056 25.7667 24.9049 25.7684 25.0056C25.7702 25.1063 25.7517 25.2063 25.714 25.2997C25.6762 25.3931 25.6201 25.4779 25.5489 25.5491C25.4777 25.6203 25.3928 25.6765 25.2994 25.7142C25.2061 25.7519 25.106 25.7705 25.0053 25.7687C24.9046 25.7669 24.8053 25.7449 24.7133 25.7039C24.6213 25.6629 24.5385 25.6038 24.4698 25.5301L15.9998 17.0613L7.52985 25.5301C7.38767 25.6626 7.19963 25.7347 7.00532 25.7313C6.81102 25.7278 6.62564 25.6491 6.48822 25.5117C6.35081 25.3743 6.2721 25.1889 6.26867 24.9946C6.26524 24.8003 6.33737 24.6123 6.46985 24.4701L14.9386 16.0001L6.46985 7.53009C6.33737 7.38792 6.26524 7.19987 6.26867 7.00557C6.2721 6.81127 6.35081 6.62588 6.48822 6.48847C6.62564 6.35106 6.81102 6.27234 7.00532 6.26892C7.19963 6.26549 7.38767 6.33761 7.52985 6.47009L15.9998 14.9388L24.4698 6.47009C24.612 6.33761 24.8001 6.26549 24.9944 6.26892C25.1887 6.27234 25.3741 6.35106 25.5115 6.48847C25.6489 6.62588 25.7276 6.81127 25.731 7.00557C25.7344 7.19987 25.6623 7.38792 25.5298 7.53009L17.0611 16.0001L25.5298 24.4701Z"
          fill="#0C0C0D"
        />
      </svg>

      <h2 class="fs-24 font-bold uppercase mb-4 text-center">
        {{ $t("Thông tin nạp điểm") }}
      </h2>
      <div class="flex flex-col gap-3">
        <div class="flex gap-1 items-center fs-18">
          <div class="font-semibold">{{ $t("Tổng số tiền nạp:") }}</div>
          <div class="font-light">{{ totalMoney }}</div>
        </div>
        <div class="flex gap-1 items-center fs-18">
          <div class="font-semibold">{{ $t("Tổng số điểm nạp:") }}</div>
          <div class="font-light">{{ totalPoints }}</div>
        </div>
        <div class="flex gap-1 items-center fs-18">
          <div class="font-semibold">{{ $t("Thời gian nạp:") }}</div>
          <div class="font-light">{{ createdAt }}</div>
        </div>
        <div class="flex gap-1 items-center fs-18">
          <div class="font-semibold">{{ $t("Người nạp:") }}</div>
          <div class="font-light">
            {{
              depositDetail.create_by_user && depositDetail.create_by_user.name
            }}
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 mt-4">
        <div class="flex flex-col md:flex-row gap-2 justify-between">
          <div class="font-semibold fs-18">{{ $t("Người nhận điểm") }}</div>
          <!-- <el-button
            @click="handlePrint"
            type="primary"
            size="small"
            class="px-4 hidden-on-pdf"
            style="color:white !important"
          >{{ $t('In') }}</el-button>-->
        </div>
        <div>
          <el-table :data="listData" border style="width: 100% !important">
            <el-table-column
              width="100"
              type="index"
              :label="$t('STT')"
            ></el-table-column>

            <el-table-column
              prop="user.name"
              :label="$t('Tên nhân viên')"
            ></el-table-column>
            <el-table-column
              property="user.email"
              :label="$t('Email')"
            ></el-table-column>
            <el-table-column
              :formatter="
                (row) =>
                  mdtUtils.getRoleMember(
                    row &&
                      row.user &&
                      row.user.doctor_infor &&
                      row.user.doctor_infor.type
                  )
              "
              :label="$t('Chức danh')"
            ></el-table-column>
            <el-table-column
              :formatter="(row) => appUtils.numberFormat(row && row.point_use)"
              :label="$t('Số điểm nạp')"
            ></el-table-column>
          </el-table>
          <AppPagination
            :paginationProps="pagination"
            @onChangePagination="handleGetListData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AppPagination } from '../../Common'
import appUtils from '../../../utils/appUtils'
import mdtUtils from '../../../utils/mdtUtils'

export default {
  name: 'DepositDetailWS',
  components: { AppPagination },
  data () {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      listData: [],
      depositDetail: {},
      isLoading: false,
      mdtUtils,
      appUtils
    }
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        this.handleGetDepositDetail()
        this.handleGetDepositMembers()
      }
    }
  },

  computed: {
    totalMoney () {
      if (!this.depositDetail?.meta_data?.total_money) return ''

      return appUtils.numberFormat(
        this.depositDetail?.meta_data?.total_money,
        'VND'
      )
    },
    totalPoints () {
      if (!this.depositDetail?.meta_data?.total_point) return ''

      return appUtils.numberFormat(
        this.depositDetail?.meta_data?.total_point,
        'Điểm'
      )
    },
    createdAt () {
      if (!this.depositDetail?.created_at) return ''

      return window
        .moment(this.depositDetail?.created_at)
        .format('HH:mm DD/MM/YYYY')
    }
  },
  created () {
    this.handleGetDepositDetail()
    this.handleGetDepositMembers()
  },
  methods: {
    handlePrint () {
      const myWindow = window.open('', 'PRINT', 'height=400,width=600')
      const bodyHTML = document.getElementById('DepositDetail').innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })
      myWindow.document.write(tempHTML)
      myWindow.document.close() // necessary for IE >= 10
      myWindow.focus() // necessary for IE >= 10*/

      myWindow.print()
      myWindow.close()

      return true
    },
    async handleGetListData () {
      try {
      } catch (error) {
        console.log(error)
      }
    },
    handleBack () {
      this.$router.replace({ name: 'DepositPointsWS' })
    },
    async handleGetDepositDetail () {
      if (!this.$globalClinic?.org_id || !this.$route.params?.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDepositDetail(this.$route.params?.id, {
            org_id: this.$globalClinic?.org_id
          })

        this.depositDetail = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetDepositMembers () {
      try {
        if (!this.$globalClinic?.org_id || !this.$route.params?.id) return

        const params = {
          org_id: this.$globalClinic?.org_id,
          sort_by: 'created_at',
          order: 'desc',
          page_num: 1,
          page_size: 10000
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDepositMembers(this.$route.params?.id, params)

        this.listData = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-container * {
  color: black;
}

.font-light {
  font-weight: 400;
}

::v-deep {
  thead * {
    color: black !important;
  }
}
</style>